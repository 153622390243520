import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { HeroOverlayQueryQuery } from "../../../__generated__/graphql-gatsby"

export type OverlayProps = {
  className?: string | undefined
}
export type IOverlay = React.FC<OverlayProps>

const Overlay: IOverlay = ({ className }) => {
  const query = useStaticQuery<HeroOverlayQueryQuery>(graphql`
    query HeroOverlayQuery {
      file(relativePath: { eq: "think-outside-the-pallet.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  `)

  const { gatsbyImageData } = { ...query.file?.childImageSharp }

  return (
    <GatsbyImage image={gatsbyImageData} alt="think outside the pallet" className={className} />
  )
}

export default Overlay
