import { GatsbyImage } from "gatsby-plugin-image"
import React, { Fragment } from "react"
import HeroCarousel from "react-hero-carousel"
import styled, { css } from "styled-components"
import { shuffleArray } from "../../../helpers"
import { lgMediaQuery, mdMediaQuery, smMediaQuery, xsMediaQuery } from "../../../theme/globalStyles"
import { CarrouselFragment, CarrouselHeightFragment } from "../../../__generated__/graphql-gatsby"
import {
  CarrouselFragment as PreviewCarrouselFragment,
  CarrouselHeightFragment as PreviewCarrouselHeightFragment,
} from "../../../__generated__/graphql-wp-sdk"
import Overlay from "./overlay"

interface SingleImgProps {
  dataHeight: Maybe<CarrouselHeightFragment | PreviewCarrouselHeightFragment>
}
const SingleImg = styled.div<SingleImgProps>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  height: ${({ dataHeight }) => dataHeight?.heightXl};
  width: 100%;

  ${({ dataHeight }) =>
    lgMediaQuery(
      css`
        height: ${dataHeight?.heightLg};
      `
    )};

  ${({ dataHeight }) =>
    mdMediaQuery(
      css`
        height: ${dataHeight?.heightMd};
      `
    )};

  ${({ dataHeight }) =>
    smMediaQuery(
      css`
        height: ${dataHeight?.heightSm};
      `
    )};

  ${({ dataHeight }) =>
    xsMediaQuery(
      css`
        height: ${dataHeight?.heightXs};
      `
    )};
`

interface HeroWrapperProps {
  dataHeight: Maybe<CarrouselHeightFragment | PreviewCarrouselHeightFragment>
}
const HeroWrapper = styled.div<HeroWrapperProps>`
  position: relative;

  :first-child {
    > div > div {
      display: flex;
      align-items: center;
    }

    overflow: hidden;
    width: 100%;

    height: ${({ dataHeight }) => dataHeight?.heightXl};

    ${({ dataHeight }) =>
      lgMediaQuery(
        css`
          height: ${dataHeight?.heightLg};
        `
      )};

    ${({ dataHeight }) =>
      mdMediaQuery(
        css`
          height: ${dataHeight?.heightMd};
        `
      )};

    ${({ dataHeight }) =>
      smMediaQuery(
        css`
          height: ${dataHeight?.heightSm};
        `
      )};

    ${({ dataHeight }) =>
      xsMediaQuery(
        css`
          height: ${dataHeight?.heightXs};
        `
      )};
  }
`
const AltHeroWrapper = styled(HeroWrapper)<HeroWrapperProps>`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: ${({ dataHeight }) => dataHeight?.heightXl};

  ${({ dataHeight }) =>
    lgMediaQuery(
      css`
        height: ${dataHeight?.heightLg};
      `
    )};

  ${({ dataHeight }) =>
    mdMediaQuery(
      css`
        height: ${dataHeight?.heightMd};
      `
    )};

  ${({ dataHeight }) =>
    smMediaQuery(
      css`
        height: ${dataHeight?.heightSm};
      `
    )};

  ${({ dataHeight }) =>
    xsMediaQuery(
      css`
        height: ${dataHeight?.heightXs};
      `
    )};
`

const OverlayImg = styled(Overlay)`
  width: 300px;
  /* height: auto !important; */
  position: absolute !important;
  z-index: 999;
  /* transform: none !important; */

  top: -3.5rem !important;
  left: 10rem !important;

  ${lgMediaQuery(
    css`
      width: 300px;
      top: -2.8rem !important;
      left: 4rem !important;
    `
  )}

  ${mdMediaQuery(
    css`
      width: 260px;
      top: -2.4rem !important;
      left: 2.5rem !important;
    `
  )}

  ${smMediaQuery(
    css`
      width: 180px;
      top: -1.8rem !important;
      left: 1.8rem !important;
    `
  )}

  ${xsMediaQuery(
    css`
      width: 140px;
      top: -1.8rem !important;
      left: 1.4rem !important;
    `
  )}
`

interface IProps {
  carousel: Maybe<CarrouselFragment | PreviewCarrouselFragment>
  altWrapper?: boolean
}
const Carousel: React.FC<IProps> = ({ carousel, altWrapper }) => {
  if (!carousel) return null

  const settings = carousel.carrousel?.carrousel_settings
  const images = carousel.carrousel?.carrousel_images

  if (!images?.images) return null

  if (settings?.isSingle)
    return (
      <SingleImg dataHeight={settings?.height}>
        {images.images.map((img, i) => {
          const image = img?.image

          const gatsbyImageData =
            image?.__typename === "WpMediaItem"
              ? image.localFile?.childImageSharp?.gatsbyImageData
              : null
          const altText = image?.__typename === "WpMediaItem" ? image?.altText : "preview"

          let url: Maybe<string> = undefined

          if (image?.__typename === "WpMediaItem") {
            url = image.localFile?.publicURL
          } else if (image?.__typename === "MediaItem") {
            url = image.mediaItemUrl
          }

          return settings.isAnimated ? (
            <Fragment key={i}>
              <img src={String(url)} alt={String(altText)} height="100%" />
              {settings?.overlay && <OverlayImg />}
            </Fragment>
          ) : (
            <Fragment key={i}>
              {image?.__typename === "WpMediaItem" ? (
                <GatsbyImage
                  image={gatsbyImageData}
                  alt={String(altText)}
                  style={{ height: "100%" }}
                />
              ) : (
                <img src={String(url)} alt={String(altText)} height="100%" />
              )}
              {settings?.overlay && <OverlayImg />}
            </Fragment>
          )
        })}
      </SingleImg>
    )

  const interval = settings?.duur ? settings.duur * 1000 : 3000
  const slides = settings?.randomStart ? shuffleArray([...images?.images]) : images.images
  const Wrapper = altWrapper ? AltHeroWrapper : HeroWrapper

  return (
    <Wrapper dataHeight={settings?.height}>
      <HeroCarousel interval={interval} height="100%">
        {slides.map((img, i) => {
          switch (img?.image?.__typename) {
            case "WpMediaItem":
              return (
                <GatsbyImage
                  key={i}
                  image={img?.image?.localFile?.childImageSharp?.gatsbyImageData}
                  objectPosition="center"
                  objectFit="cover"
                  alt={String(img?.image?.altText)}
                />
              )
            case "MediaItem":
              return (
                <img
                  key={i}
                  src={String(img?.image?.mediaItemUrl)}
                  alt="preview"
                  style={{
                    objectPosition: "center",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              )
            default:
              return null
          }
        })}
      </HeroCarousel>
      {settings?.overlay && <OverlayImg />}
    </Wrapper>
  )
}

export default Carousel
